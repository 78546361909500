<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">销售到期应收明细</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="起始日期:">
                                        <el-date-picker
                                                v-model="startDate"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                placeholder="选择日期"
                                                :picker-options="pickerOptions">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="结束日期:" >
                                        <el-date-picker
                                                v-model="endDate"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                placeholder="选择日期"
                                                :picker-options="pickerOptions">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="2" :sm="12" :md="8">
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" round  size="mini">搜索查询</el-button>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                                        </vxe-toolbar>
                                </el-col>
                                <el-dialog width="420px" title="盘点设置" :visible.sync="dialogFormVisible">
                                    <el-form :model="form">
                                        <el-form-item label="盘点月份:">
                                            <el-date-picker
                                                    v-model="startmonth"
                                                    align="right"
                                                    type="month"
                                                    size="mini"
                                                    placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="起始日期:">
                                            <el-date-picker
                                                    v-model="startDate"
                                                    align="right"
                                                    type="date"
                                                    size="mini"
                                                    placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="结束日期:">
                                            <el-date-picker
                                                    v-model="endDate"
                                                    align="right"
                                                    type="date"
                                                    size="mini"
                                                    placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-form>
                                    <div slot="footer" class="dialog-footer">
                                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                                        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                                    </div>
                                </el-dialog>

                            </el-row>
                        </el-col>

                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid ref="xGrid" :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv']}" :print-config="{type: ['xlsx'] ,types: ['xlsx', 'csv']}" v-bind="gridOptions" @page-change="handlePageChange">
            <template #operate="{row}">
                <template v-if="$refs.xGrid.isActiveByRow(row)">
                    <vxe-button icon="el-icon-document-checked" status="primary" title="保存" circle @click="saveRowEvent(row)"></vxe-button>
                </template>
                <template v-else>
                    <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="editRowEvent(row)"></vxe-button>
                </template>
                <vxe-button icon="el-icon-delete" title="删除" circle @click="removeRowEvent(row)"></vxe-button>
            </template>
        </vxe-grid>
    </el-col>


</el-row>

                </el-form>
            </el-main>
    </div>
</template>
<!--border
resizable
show-overflow
keep-source
ref="xGrid"
size="mini"
height="650"
highlight-hover-row
:print-config="{}"
:data="tableDataMain"
:columns="tableColumn"-->
<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                data:this.tableDataMain,
                gridOptions: {
                    border: true,
                    resizable: true,
                    keepSource: true,
                    showOverflow: true,
                    height: 650,
                    loading: false,

                    pagerConfig: {
                        total: 0,
                        currentPage: 1,
                        pageSize: 10,
                        pageSizes: [10, 20, 50, 100, 200, 500]
                   },
                    editConfig: {
                        // 设置触发编辑为手动模式
                        trigger: 'manual',
                        // 设置为整行编辑模式
                        mode: 'row',
                        // 显示修改状态和新增状态
                        showStatus: true,
                        // 自定义可编辑列头的图标
                        icon: 'fa fa-file-text-o'
                   },columns: [
                        {field:'unit', width: 100 ,title: '下单日期' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '单号' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '客户名称' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },

                        {field:'unit', width: 100 ,title: '收款期限' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '应收金额' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '已收金额' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '未收金额' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 100 ,title: '逾期天数' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },
                        {field:'unit', width: 70 ,title: '业务员' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       },{field:'unit', width: 100 ,title: '开单' , slots:{
                                header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                           }
                       }
                    ],data : [],
               },
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },


              filterData: {},
                unit:[
                    {id:1,value:1,unit:'吨',label:'吨'},
                    {id:2,value:2,unit:'支',label:'支'}
                ],
                category:[
                    {id:1,category:'方管',value:1,label:'方管'},
                    {id:2,category:'矩管',value:2,label:'矩管'}
                ],
                startmonth:'',
                startDate:'',
                endDate:'',
                specification:[
                    {id:1,specification:'60*40*4*6米',value:1,label:'60*40*4*6米'},
                    {id:2,specification:'200*100*4*6米',value:2,label:'200*100*4*6米'},
                    {id:3,specification:'200*100米4*12米',value:3,label:'200*100米4*12米'},
                    {id:4,specification:'150*100*4*6米',value:4,label:'150*100*4*6米'},
                    {id:5,specification:'50*25*3*6米',value:5,label:'50*25*3*6米'},
                    {id:6,specification:'50*50*3.5*6米',value:6,label:'50*50*3.5*6米'},
                    {id:7,specification:'160*60*6*6米',value:7,label:'160*60*6*6米'},
                    {id:8,specification:'200*100*6*12米',value:8,label:'200*100*6*12米'},
                    {id:9,specification:'250*250*12*12米',value:9,label:'250*250*12*12米'},
                    {id:10,specification:'60*60*6*米',value:10,label:'60*60*6*米'},
                    ],
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
               },
                activeName:'first',
                checked: false,
                regionList: [],
                restaurants: [
                    {value: '前端', name: '前端'},
                    {value: '后端', name: '后端'}
                ],
                sexList: [
                    {label: '', value: ''},
                    {label: '男', value: '1'},
                    {label: '女', value: '0'}
                ],

           }
       },
        methods: {
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                //this.findList()
           },
            editRowEvent (row) {
                const $grid = this.$refs.xGrid
                $grid.setActiveRow(row)
           },
            saveRowEvent () {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.gridOptions.loading = true
                    setTimeout(() => {
                        this.gridOptions.loading = false
                        this.$XModal.message({content: '保存成功！', status: 'success'})
                   }, 300)
               })
           },
            removeRowEvent (row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    const $grid = this.$refs.xGrid
                    if (type === 'confirm') {
                        $grid.remove(row)
                   }
               })
           },
            roleFetchSuggestions (queryString, cb) {
                var restaurants = this.restaurants
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    cb(results)
               }, 1000 )
           },
            editMethod ({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName ({rowIndex}) {
                if (rowIndex%2 === 1) {
                    return 'success-row';
               } else{
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           }
       },
        created () {
            var list = [];
            for (var index = 1; index < 10; index++) {
                list.push({
                    month: "2021年"+index+"月",
                    startDate: "2021年"+index+"月1日",
                    endDate: "2021年"+index+"月30日",
                    remark: "已结转",
               });
           }
            this.loading = true
            this.gridOptions.data = list;
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
</style>
